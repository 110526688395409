import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Card } from "../../../shared-module/components/card/card.component";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { PageTitleSetter } from "../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../extensions/application-progress-bar/application-progress-bar.component";
import { GroupButtons } from "../../../shared-module/components/group-buttons/group-buttons.component";
import { LookupResource } from "../../../shared-module/api-resources/lookup.resource";
import { ApplicationResource } from "../../../shared-module/api-resources/application.resource";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../shared-module/helpers/utility";
import { ApplicantDemographicsStore, IApplicantDemographics } from "./applicant-demographics.store";
import { useFormValidation } from "../../../shared-module/hooks/use-form-validation/use-form-validation.hook";
import { ErrorContainer } from "../../../shared-module/components/error-container/error-container.component";
import { BdsMultiSelect } from "../../../shared-module/components/multi-select/multi-select.component";
import { DebugContainer } from "../../../shared-module/components/debug-container/debug-container.component";
import { ApplicantDemographicsValidationSchema } from "./applicant-demographics.validation";
import { ApiConfig } from "shared-module/services/api-config";
import { IsAuthenticated } from "shared-module/components/is-authenticated/is-authenticated";
import { ApplicationExpirationError, GatewayNetworkError } from "shared-module/models/common.models";
import { SessionService } from "shared-module/services/session.service";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";

function ApplicantDemographics() {
  const navigate = useNavigate();
  const { id: applicationRefId } = useParams();

  const lookupService = new LookupResource(ApiConfig);
  const applicationService = new ApplicationResource(ApiConfig);

  const [genders, setGenders] = useState<GatewayOapApi.GenderModel[]>([]);
  const [maritalStatuses, setMaritalStatuses] = useState<GatewayOapApi.MaritalStatusModel[]>([]);
  const [educationalTypesNoSchool, setEducationalTypesNoSchool] = useState<GatewayOapApi.EducationalTypeModel[]>([]);
  const [educationalTypesSchool, setEducationalTypesSchool] = useState<GatewayOapApi.EducationalTypeModel[]>([]);
  const [preferredLanguages, setPreferredLanguages] = useState<GatewayOapApi.PreferredLanguageModel[]>([]);
  const [otherLanguageRequired, setOtherLanguageRequired] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isMinor, setIsMinor] = useState<boolean>(false);
  const [savedState, setSavedState] = useState<IApplicantDemographics>({} as IApplicantDemographics);
  const [fromSummary, setFromSummary] = useState<boolean>(false);
  const store = ApplicantDemographicsStore;

  const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched, touchedAll } =
    useFormValidation(ApplicantDemographicsValidationSchema, store);

  useEffect(() => {
    if (!hasApplicationRefId()) {
      NavigationHelper.gotoHome(navigate);
      return;
    }

    setIsLoading(true);
    Utility.showLoadingOverlay();

    Promise.all([
      lookupService.getGenders(),
      lookupService.getMaritalStatuses(),
      lookupService.getEducationalTypesNoSchool(),
      lookupService.getEducationalTypesSchool(),
      lookupService.getPreferredLanguages(),
      applicationService.getApplication(applicationRefId)
    ])
      .then((response) => {
        setGenders(response[0].records);
        setMaritalStatuses(response[1].records);
        setEducationalTypesNoSchool(response[2].records);
        setEducationalTypesSchool(response[3].records);
        setPreferredLanguages(response[4].records);
        setIsMinor(response[5].record.isMinor);
        store.refresh(response[5].record);
        setOtherLanguageRequired(store.applicantPreferredLanguageId == "16");
        setSavedState(store.buildState(response[5].record));
        setFromSummary(sessionStorage.getItem('fromSummary') == 'true' || false);
        return true;
      })
      .catch((ex) => {
        if (ex instanceof ApplicationExpirationError) {
          Utility.alert("Application Timeout", ex.errorMessage);
        }
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
        NavigationHelper.gotoHome(navigate);
      })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });

  }, []);

  function hasApplicationRefId() {
    return !!applicationRefId;
  }

  function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
    if (currentValue === savedValue) {
      return;
    }

    setIsSaving(true);
    saveApplicantDemographics(eventSource)
      .finally(() => {
        setIsSaving(false);
      });
  }

  function saveApplicantDemographics(eventSource: string): Promise<any> {
    var isMultiRacial = !!store.findRaceOption(store.applicantRaceOptions, "1");
    var isAmericanIndianOrAlaskanNative = !!store.findRaceOption(store.applicantRaceOptions, "2");
    var isBlackAfricanAmerican = !!store.findRaceOption(store.applicantRaceOptions, "3");
    var isNativeHawaiianPacificIslander = !!store.findRaceOption(store.applicantRaceOptions, "4");
    var isAsian = !!store.findRaceOption(store.applicantRaceOptions, "5");
    var isWhite = !!store.findRaceOption(store.applicantRaceOptions, "6");

    setIsSubmitting(true, eventSource);

    var isStudent = Utility.getBooleanToTypedResult<boolean>([
      { condition: store.isCurrentlyStudent === "is-currently-student", value: true },
      { condition: store.isCurrentlyStudent === "is-currently-not-student", value: false }
    ], null);

    var isLatinoOrHispanic = Utility.getBooleanToTypedResult<boolean>([
      { condition: store.applicantIsHispanicOrLatino === "is-hispanic-latino", value: true },
      { condition: store.applicantIsHispanicOrLatino === "is-not-hispanic-latino", value: false }
    ], null);

    return applicationService.saveApplicantDemographics(
      applicationRefId,
      Utility.stringToNumber(store.applicantGenderId, null),
      Utility.stringToNumber(store.applicantMaritalStatusId, null),
      isStudent,
      (isStudent != null && isStudent) ?
        Utility.stringToNumber(store.applicantEnrolledEducationTypeId, null) :
        Utility.stringToNumber(store.applicantNotEnrolledEducationTypeId, null),
      isLatinoOrHispanic,
      isMultiRacial,
      isAmericanIndianOrAlaskanNative,
      isBlackAfricanAmerican,
      isNativeHawaiianPacificIslander,
      isAsian,
      isWhite,
      Utility.stringToNumber(store.applicantPreferredLanguageId, null),
      store.applicantPreferredLanguageOther || null,
      store.hasApplicantVrsAssessed == "has-vrs-assessment",
      store.hasLegalRepresentative == "has-legal-representative")
      .then((response: GatewayOapApi.ApiBooleanResponse) => {
        return applicationService.getApplication(applicationRefId)
      })
      .then((response) => {
        setSavedState(store.buildState(response.record));
        var guestSessionId = sessionStorage.getItem("guestSessionId") || "0";
        if (guestSessionId != "0") {
          SessionService.slideGuestSession(Number(guestSessionId));
          EventBroadcasterService.RaiseGuestSessionExpirationEvent({
            isAuthenticated: false,
            remainingTimeInSeconds: 900,
            hasActiveMaintenance: false
          });
        }
        return true;
      })
      .catch(ex => {
        if (ex instanceof ApplicationExpirationError) {
          Utility.alert("Application Timeout", ex.errorMessage);
        }
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
        NavigationHelper.gotoHome(navigate);
      })
      .finally(() => {
        setIsSubmitting(false, null);
      });
  }

  function handleReturnToSummary(){
    touchedAll();
    if (ApplicantDemographicsValidationSchema.isValidSync(store)) {
      if (store.hasLegalRepresentative == "has-legal-representative") {
        NavigationHelper.gotoLegalRepresentative(navigate, applicationRefId);
      } else {
        NavigationHelper.gotoApplicationSummary(navigate, applicationRefId);
      }
      return;
    }

    scrollTo(0, 0);
  }

  function handleGoToNextStep() {
    touchedAll();
    if (ApplicantDemographicsValidationSchema.isValidSync(store)) {
      if (store.hasLegalRepresentative == "has-legal-representative") {
        NavigationHelper.gotoLegalRepresentative(navigate, applicationRefId);
      } else {
        NavigationHelper.gotoApplicationSummary(navigate, applicationRefId);
      }
      return;
    }

    debugger;
    scrollTo(0, 0);
  }

  return (
    <>
      <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>
      <PageTitleSetter step={4} totalSteps={8}></PageTitleSetter>
      <div className="mt-4">
        <ApplicationProgressBar step={4} totalSteps={8}></ApplicationProgressBar>
      </div>

      <ShowThen when={!isLoading}>
        <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
      </ShowThen>

      <form>
        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">
              What is the applicant’s gender?
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <select name="applicantGenderId" value={store.applicantGenderId}
                  onChange={(event) => {
                    store.setApplicantGenderId(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantGenderId");
                    handleValueChange(event,
                      "applicantGenderId",
                      store.applicantGenderId,
                      savedState.applicantGenderId);
                  }}
                  className="bds-form-select bds-section-select form-select">
                  <option value="">Please Select ...</option>
                  {
                    genders.map((item, index) => (
                      <option key={index} value={item.genderId}>{item.genderName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">
              What is the applicant's marital status?
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <select name="applicantMaritalStatusId" value={store.applicantMaritalStatusId}
                  onChange={(event) => {
                    store.setApplicantMaritalStatusId(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantMaritalStatusId");
                    handleValueChange(event,
                      "applicantMaritalStatusId",
                      store.applicantMaritalStatusId,
                      savedState.applicantMaritalStatusId);
                  }}
                  className="bds-form-select bds-section-select form-select">
                  <option value="">Please Select ...</option>
                  {
                    maritalStatuses.map((item, index) => (
                      <option key={index} value={item.maritalStatusId}>{item.maritalStatusName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">Is the applicant currently a student?</div>
            <div className="bds-section-details mt-2">
              This can include public, private, or home school educational settings.
            </div>
            <div className="mt-4">
              <GroupButtons name="isCurrentlyStudent" value={store.isCurrentlyStudent}
                onChange={(event) => {
                  setFieldsTouched("isCurrentlyStudent");
                  store.setIsCurrentlyStudent(event.value);
                  handleValueChange(event,
                    "isCurrentlyStudent",
                    store.isCurrentlyStudent,
                    savedState.isCurrentlyStudent);
                }}
                onBlur={(event) => {
                  setFieldsTouched("isCurrentlyStudent");
                }}
                items={[
                  { itemValue: "is-currently-student", content: <>Yes</> },
                  { itemValue: "is-currently-not-student", content: <>No</> }
                ]}>
              </GroupButtons>
            </div>
          </Card>
        </div>

        <ShowThen when={store.isCurrentlyStudent == "is-currently-student"}>
          <div className="mt-4">
            <Card>
              <div className="bds-section-heading mandatory">
                What type of educational program is the applicant currently enrolled in?
              </div>
              <div className="bds-section-details mt-2">
                Please choose the option that best describes the current grade level.
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <select name="applicantEnrolledEducationTypeId" value={store.applicantEnrolledEducationTypeId}
                    onChange={(event) => {
                      store.setApplicantEnrolledEducationTypeId(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("applicantEnrolledEducationTypeId");
                      handleValueChange(event,
                        "applicantEnrolledEducationTypeId",
                        store.applicantEnrolledEducationTypeId,
                        savedState.applicantEnrolledEducationTypeId);
                    }}
                    className="bds-form-select bds-section-select form-select">
                    <option value="">Please Select ...</option>
                    {
                      educationalTypesSchool.map((item, index) => (
                        <option key={index} value={item.educationalTypeId}>{item.educationalTypeName}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </Card>
          </div>
        </ShowThen>

        <ShowThen when={store.isCurrentlyStudent == "is-currently-not-student"}>
          <div className="mt-4">
            <Card>
              <div className="bds-section-heading">
                If the applicant is no longer in school, what is the highest level of education
                that the applicant has obtained?
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <select name="applicantNotEnrolledEducationTypeId" value={store.applicantNotEnrolledEducationTypeId}
                    onChange={(event) => {
                      store.setApplicantNotEnrolledEducationTypeId(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("applicantNotEnrolledEducationTypeId");
                      handleValueChange(event,
                        "applicantNotEnrolledEducationTypeId",
                        store.applicantNotEnrolledEducationTypeId,
                        savedState.applicantNotEnrolledEducationTypeId);
                    }}
                    className="bds-form-select bds-section-select form-select">
                    <option value="">Please Select ...</option>
                    {
                      educationalTypesNoSchool.map((item, index) => (
                        <option key={index} value={item.educationalTypeId}>{item.educationalTypeName}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </Card>
          </div>
        </ShowThen>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the applicant’s ethnicity/race?</div>
            <div className="bds-section-details mt-2">
              The applicant is not required to answer the ethnicity and race question. We are
              asking these questions to help improve our programs. BDS values each individual's
              civil rights and wishes to provide equal opportunity and equitable service.
              BDS does not discriminate on the basis of ethnicity, race, religion, color,
              national origin, ancestry, citizenship, sex, gender identity, sexual orientation,
              marital or domestic partner status, veteran status, age, disability, genetic
              information or any other characteristic protected under law. The applicant's answer
              will not be used to make a decision about the applicant's programs and benefits.
            </div>
            <div className="bds-section-heading mt-3">Is the applicant Hispanic or Latino?</div>
            <div className="mt-4">
              <GroupButtons name="applicantIsHispanicOrLatino" value={store.applicantIsHispanicOrLatino}
                onChange={(event) => {
                  setFieldsTouched("applicantIsHispanicOrLatino");
                  store.setApplicantIsHispanicOrLatino(event.value);
                  handleValueChange(event,
                    "applicantIsHispanicOrLatino",
                    store.applicantIsHispanicOrLatino,
                    savedState.applicantIsHispanicOrLatino);
                }}
                onBlur={(event) => {
                  setFieldsTouched("applicantIsHispanicOrLatino");
                }}
                items={[
                  { itemValue: "is-hispanic-latino", content: <>Yes</> },
                  { itemValue: "is-not-hispanic-latino", content: <>No</> }
                ]}>
              </GroupButtons>
            </div>
            <div className="bds-section-heading mt-4">
              What is the applicant's race?
            </div>
            <div className="bds-section-details mt-2">
              Multiple selections can be made.
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <BdsMultiSelect name="applicantRaceOptions"
                  value={store.applicantRaceOptions}
                  onChange={(event) => {
                    setFieldsTouched("applicantRaceOptions");
                    store.setApplicantRaceOptions(event);
                    handleValueChange(event,
                      "applicantRaceOptions",
                      store.applicantRaceOptions,
                      savedState.applicantRaceOptions);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantRaceOptions");
                  }}
                  isClearable={true} isDisabled={false} isSearchable={true}
                  className={"bds-form-select bds-section-select"}
                  loadingMessage={() => { return <>Loading...</> }}
                  options={store.raceOptions}></BdsMultiSelect>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the applicant's preferred language?</div>
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <div className="bds-form-label form-label mt-2">Please select from the following list.</div>
                <select name="applicantPreferredLanguageId" value={store.applicantPreferredLanguageId}
                  onChange={(event) => {
                    store.setApplicantPreferredLanguageId(event.target.value);
                    store.setApplicantPreferredLanguageOther("");
                    setOtherLanguageRequired(store.applicantPreferredLanguageId == "16")
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantPreferredLanguageId", "applicantPreferredLanguageOther");
                    handleValueChange(event,
                      "applicantPreferredLanguageId",
                      store.applicantPreferredLanguageId,
                      savedState.applicantPreferredLanguageId);
                  }}
                  className="bds-form-select bds-grid-form form-select">
                  <option value="">Please Select ...</option>
                  {
                    preferredLanguages.map((item, index) => (
                      <option key={index} value={item.preferredLanguageId}>{item.preferredLanguageName}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-12 col-md-6">
                <ShowThen when={otherLanguageRequired}>
                  <div className="bds-form-label form-label mt-2 mandatory">If other, please specify.</div>
                </ShowThen>
                <ShowThen when={!otherLanguageRequired}>
                  <div className="bds-form-label form-label mt-2">If other, please specify.</div>
                </ShowThen>
                <input type="text" name="applicantPreferredLanguageOther"
                  disabled={store.applicantPreferredLanguageId != '16'}
                  value={store.applicantPreferredLanguageOther}
                  onChange={(event) => { store.setApplicantPreferredLanguageOther(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantPreferredLanguageOther");
                    handleValueChange(event,
                      "applicantPreferredLanguageOther",
                      store.applicantPreferredLanguageOther,
                      savedState.applicantPreferredLanguageOther);
                  }}
                  className="bds-form-text form-control" />
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">
              Vocational Rehabilitation Services is a group within DDRS that assists eligible
              individuals with disabilities to achieve their employment goals. Has the applicant
              ever been assessed by Vocational Rehabilitation Services?
            </div>
            <div className="bds-section-details mt-2">
              Select "No" if the answer is unknown.
            </div>
            <div className="mt-4">
              <GroupButtons name="hasApplicantVrsAssessed" value={store.hasApplicantVrsAssessed}
                onChange={(event) => {
                  setFieldsTouched("hasApplicantVrsAssessed");
                  store.setHasApplicantVrsAssessed(event.value);
                  handleValueChange(event,
                    "hasApplicantVrsAssessed",
                    store.hasApplicantVrsAssessed,
                    savedState.hasApplicantVrsAssessed);
                }}
                onBlur={(event) => {
                  setFieldsTouched("hasApplicantVrsAssessed");
                }}
                items={[
                  { itemValue: "has-vrs-assessment", content: <>Yes</> },
                  { itemValue: "has-not-vrs-assessment", content: <>No</> }
                ]}>
              </GroupButtons>
            </div>
          </Card>
        </div>

        <ShowThen when={!isMinor}>
          <div className="mt-4">
            <Card>
              <div className="bds-section-heading">
                Does the applicant have someone who has been legally designated to help make decisions?
              </div>
              <div className="bds-section-details mt-2">
                This can include a legal representative, Power of Attorney, Healthcare Representative,
                Legal Representative Ad Litem, or applicants currently involved with DCS/APS.
                Please select "No" if none of these conditions apply or if you are unsure.
              </div>
              <div className="mt-4">
                <GroupButtons name="hasLegalRepresentative" value={store.hasLegalRepresentative}
                  onChange={(event) => {
                    setFieldsTouched("hasLegalRepresentative");
                    store.setHasLegalRepresentative(event.value);
                    handleValueChange(event,
                      "hasLegalRepresentative",
                      store.hasLegalRepresentative,
                      savedState.hasLegalRepresentative);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("hasLegalRepresentative");
                  }}
                  items={[
                    { itemValue: "has-legal-representative", content: <>Yes</> },
                    { itemValue: "has-not-legal-representative", content: <>No</> }
                  ]}>
                </GroupButtons>
              </div>
            </Card>
          </div>
        </ShowThen>

        <div className="row mt-4">
          <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
            <button type="button" className="btn bds-btn-primary"
              onClick={() => NavigationHelper.gotoApplicantInformation(navigate, applicationRefId)}>Back</button>
            <IsAuthenticated>
              <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
            </IsAuthenticated>
          </div>
          <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end mt-3 mt-md-0">
            <ShowThen when={fromSummary}>
              <button type="button" disabled={isLoading}
                onClick={() => handleReturnToSummary()} 
                className="btn bds-btn-primary me-3">Return To Summary
              </button>
            </ShowThen>
            <button type="button" disabled={isLoading}
              onClick={(event) => handleGoToNextStep()}
              className="btn bds-btn-primary">Next</button>
          </div>
        </div>
      </form>
    </>
  );
}

export default observer(ApplicantDemographics);